<template>
	<div class="page">
		<up_files ref="file_import" :show_mb="false" :show_type="false" types="[]" />

		<!-- :groupList="groupList" -->
		<bumen_add_modal ref="bumen_add_modal" @confirm="do_bumen_add_confirm" />
		<position_add_modal ref="position_add_modal" @confirm="do_position_add_confirm" />

		<div class="page-title">
			组织架构
		</div>
		<div class="page-ctx">
			<div class="group-box">
				<div class="group-sec-list">
					<div class="group-sec" v-for="(group,gindex) in groupList" :key="gindex" v-if="gindex>2">
						<div class="group-info">
							{{gindex + 1}}级
							组织架构
						</div>
						<div class="group-items">
							<div class="bumen-item" v-for="(item,index) in group" :key="index"
								@click="do_bumen_select(group, gindex,item)" :class="{selected: groupSelectList[gindex] == item.id}">
								<div class="bumen-icon flex-center">
									<img src="@/assets/img/bumen/mulu.png" alt="" />
								</div>
								<div class="bumen-title">{{ item.title }}</div>
								<div class="bumen-act">
									<el-popover popper-class="pop-mulu" placement="left" title width="5em" trigger="hover" content>
										<!-- <i slot="reference" class="el-icon-more"></i> -->
										<div slot="reference" class="more-box flex-center">
											<img class="more" src="@/assets/img/bumen/more.png" />
										</div>
										<ul class="tag-actions">
											<li class="tag-edit tag-common-icon" @click="do_bumen_edit(group, gindex,item)">
												<img src="@/assets/img/bumen/edit.png" alt="" />
												<div>编辑</div>
											</li>
											<li class="tag-delete tag-common-icon" @click="do_bumen_delete(group, gindex,item)">
												<img src="@/assets/img/bumen/delete.png" alt="" />
												<div class="tag-del-text">删除</div>
											</li>
										</ul>
									</el-popover>
								</div>
							</div>
						</div>
						<div class="group-add">
							<div class="btn flex-center" @click="do_bumen_add(group, gindex)">
								+ 新增部门
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="position-box">
				<div class="filter-box flex-between">
					<div class="title">
						岗位
					</div>
					<div class="search-box flex">
						<div class="search-text">搜索</div>
						<div class="search-input">
							<el-input clearable="" placeholder="岗位名称" v-model="forms.keyword" class="input-with-select">
								<el-button class="btn-search" slot="append" icon="el-icon-search"></el-button>
							</el-input>
						</div>
					</div>
					<div class="actions flex">
						<div class="btn" @click="do_position_import()">批量导入</div>
						<div class="btn" @click="do_position_add()">新增岗位</div>
					</div>
				</div>
				<div class="table-box">
					<div class="table-view">
						<!-- v-loading="loading" -->
						<el-table :data="position_list" stripe header-cell-class-name="el_table_title" style="width: 100%">
							<!-- 暂时无需 -->
							<!-- <el-table-column type="selection" width="55"></el-table-column> -->
							<el-table-column prop="sn" label="岗位编码" width=""></el-table-column>
							<el-table-column prop="title" label="岗位名称" width=""></el-table-column>
							<el-table-column prop="num" label="定员人数" width=""></el-table-column>
							<el-table-column prop="xy_num" label="现有人数" width=""></el-table-column>
							<el-table-column prop="zj_num" label="增减人数" width=""></el-table-column>
							<el-table-column prop="gw_gz" label="岗位工资" width=""></el-table-column>
							<el-table-column fixed="right" label="操作" width="">
								<template slot-scope="scope">
									<el-button type="text" size="small" class="btn_modify"
										@click="do_row_position_edit(scope.row)">编辑</el-button>
									<el-button type="text" size="small" class="btn_delete"
										@click="do_row_position_delete(scope.row)">删除</el-button>
								</template>
							</el-table-column>
						</el-table>
					</div>

					<div class="pages">
						<page :pageson='count' @row="row" @currentPage="currentPage"></page>
					</div>
				</div>

			</div>
		</div>

	</div>
</template>
<script>
	import page from "@/components/page/page.vue"; //分页  统一的
	import bumen_add_modal from '@/components/bumen/bumen_add_modal.vue'
	import position_add_modal from '@/components/bumen/position_add_modal.vue'

	import {
		mapState
	} from "vuex";
	export default {
		name: "zhishidian-guanli",
		components: {
			page,
			bumen_add_modal,
			position_add_modal,
		},
		data() {
			return {
				cate_flat_list: [], //非树形结构数据
				cate_level_map: {
					// 1: [],//最高1级分类
					// 2: [], 
				},
				groupList: [
					// [],
					// [],
				], //所有分类
				groupSelectList: [
					3, 11, 12
					// id,id2
				], //所有激活id


				count: 0,
				pages: {
					page: 1,
					limit: 10,
				},
				forms: {
					keyword: ''
				},
				position_list: [],
				loading: false
			};
		},
		computed: {
			...mapState([""]),
		},

		watch: {},

		created() {


			this.query_all_department({
				type: '初始化'
			})

		},

		methods: {
			//所有组织架构
			query_all_department(option) {
				this.$api("getCategoryAllList", {
				
				}, "get").then((res) => {
					if (res.code == 200) {
						let list = res.data;
						this.handle_cate_list(list)
						
						
						if(option.type == '初始化' && this.groupSelectList.length) {
							let child_items = this.cate_level_map[this.groupSelectList.length + 1]
							let level3_child = child_items.filter(v => v.parent_id == this.groupSelectList[this.groupSelectList.length  -1])
							this.groupList.push(level3_child)
						}
						
					}
				});
			},
			//处理所有分类数据
			handle_cate_list(list) {
				console.log('处理部门数据')
				//this.$log('获取分类分组 groupList', this.groupList)
				//this.$log('获取分类选中 groupSelectList', this.groupSelectList)
				let cate_level_map = {
					// 1: [], 
					// 2: [], 
				};
				list.forEach(v => {
					let {
						level
					} = v;
					if (!cate_level_map[level]) {
						cate_level_map[level] = []
					}
					cate_level_map[level].push(v)
				})

				this.cate_flat_list = list;
				this.cate_level_map = cate_level_map;
				//this.$log('cate_level_map', cate_level_map)


				//判断 已展开的数据项目

				if (this.groupSelectList.length) {
					let group_cates = []
					for (let deep = 1; deep <= this.groupSelectList.length; deep++) { //当前展开的层级
						let items = this.cate_level_map[deep]; //当前级别的数据列表
						//id为上一级展开项目的列表
						if (deep != 1) {
							let parent_id = this.groupSelectList[deep - 1 - 1]
							items = items.filter(v => v.parent_id == parent_id)
						}
						group_cates.push(items)
					}
					//如果勾选的分类长度  等于 已展开长度 - 1 则把最后一个选中的id作为父级id  
					let expand_group_list = this.groupList;
					if (this.groupSelectList.length == expand_group_list.length - 1) {
						let parent_id = this.groupSelectList[this.groupSelectList.length - 1]
						let deep = expand_group_list.length;
						let items = this.cate_level_map[deep]
						items = items.filter(v => v.parent_id == parent_id)
						group_cates.push(items)
					}



					// this.groupSelectList.forEach(id => {
					// 	let item = this.cate_flat_list.find(v => v.id == id)
					// 	group_cates.push(item)
					// })
					this.groupList = group_cates; //默认展开的分组
					//
				} else {
					let level_1_list = this.cate_level_map[1] || []
					this.groupList = [level_1_list];
					this.groupSelectList = []; // [level_1_list[0].id]
				}


				//this.$log('设置分类分组 groupList', this.groupList)
				//this.$log('设置分类选中 groupSelectList', this.groupSelectList)
			},

			//根据上级获取当前层级组织架构
			query_department(parent_id = 0, gindex) {
				this.$api("getCategoryList", {
					parent_id: parent_id,
					page: 1,
					limit: 100,
				}, "get").then((res) => {
					if (res.code == 200) {
						let list = res.data.list;
						// this.groupList.push(list)
						//
						let length = this.groupList.length;
						if (!length) {
							this.groupList = [list]
						} else if (gindex == length - 1) {
							this.groupList.push(list)
						} else {
							this.groupList.splice(gindex, 0, list)
						}
					}
				});
			},

			//选择当前部门
			do_bumen_select(group, gindex, item) {
				this.pages.page=1
				let curr_deep = gindex + 1;
				//console.log('当前层级', curr_deep)
				//this.$log('item', item)
				let select_id = item.id;
				if (this.groupSelectList[gindex] == select_id) {
					return
				}


				this.groupSelectList[gindex] = select_id;

				//如果当前层级少于总层级 移除后边层级
				if (curr_deep < this.groupSelectList.length) {
					let new_groupSelectList = this.groupSelectList.slice(0, curr_deep)
					this.groupSelectList = [...new_groupSelectList]
					//this.$log('重新截取新的选择id列表 new_groupSelectList', new_groupSelectList)

					let new_groupList = this.groupList.slice(0, curr_deep)
					// this.groupList = JSON.parse(JSON.stringify(new_groupList))
					this.groupList = this.groupList.slice(0, curr_deep)
				}


				this.groupSelectList = [...this.groupSelectList]
				console.log('已选择层级id groupSelectList', [...this.groupSelectList])
				// this.query_department(select_id, gindex)


				let next_level_child = this.cate_flat_list.filter(v => v.parent_id == select_id) || [];
				//this.$log('next_level_child', next_level_child);
				let next_has_position = next_level_child.some(v => v.is_gw > 0)
				//是否有岗位数据
				if (!next_has_position) { //下级无岗位 有部门
					this.groupList[curr_deep] = next_level_child
				} else { //下级有岗位 无部门
					if (this.groupList.length > curr_deep) {
						this.groupList.pop()
					}
				}

				this.query_position_list();
			},
			//部门添加
			do_bumen_add(group, gindex) {
				this.$refs.bumen_add_modal.init({
					deep: gindex + 1,
					groupList: this.groupList,
					groupSelectList: this.groupSelectList
				});
			},
			//部门添加确认
			do_bumen_add_confirm() {
				console.log('新增部门确认')
				this.query_all_department();
			},
			// 部门编辑
			do_bumen_edit(group, gindex, item) {
				//this.$log('部门编辑', item)
				this.$refs.bumen_add_modal.init({
					deep: gindex + 1,
					groupList: this.groupList,
					groupSelectList: this.groupSelectList,
					row: item
				});
			},
			// 部门删除
			do_bumen_delete(group, gindex, item) {
				//this.$log('部门删除', item)
				this.$confirm('此操作将删除该条数据,确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("delCategory", {
						id: item.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.query_all_department()
						}
					});

				}).catch(() => {

				});
			},


			//岗位导入
			do_position_import() {
				this.$refs.file_import.handle_open();
			},

			do_position_import_confirm() {
				this.query_position_list()
			},


			//新增岗位
			do_position_add() {
				this.$refs.position_add_modal.init({
					deep: this.groupSelectList.length + 1,
					groupList: this.groupList,
					groupSelectList: this.groupSelectList
				});
			},
			//
			do_position_add_confirm() {
				this.query_position_list()
			},
			//岗位列表查询
			query_position_list() {
				this.$api("getCategoryList", {
					parent_id: this.groupSelectList[this.groupSelectList.length - 1],
					...this.pages,
					...this.forms
				}, "get").then((res) => {
					if (res.code == 200) {
						let has_person = res.data.list.some(v => v.is_gw > 0)
						if (has_person) {
							this.count = res.data.count
							this.position_list = res.data.list;
							//this.$log('岗位列表', this.position_list)
						} else {
							this.count = 0
							this.position_list = [];
						}
					}
				});
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.query_position_list()
			},

			//岗位编辑
			do_row_position_edit(row) {
				this.$refs.position_add_modal.init({
					deep: this.groupSelectList.length + 1,
					groupList: this.groupList,
					groupSelectList: this.groupSelectList,
					row: row
				});
			},
			//岗位编辑
			do_row_position_delete(row) {
				this.$confirm('此操作将删除被勾选数据,确认删除?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$api("delCategory", {
						id: row.id
					}, "post").then((res) => {
						alert(res)
						if (res.code == 200) {
							this.query_position_list()
						}
					});

				}).catch(() => {

				});
			},


		},
	};
</script>

<style>
	.el-popover {
		min-width: 5em;
	}
</style>

<style scoped lang="less">
	.group-sec-list {
		display: flex;

		.group-sec {
			border-right: 1px solid #DCDCDC;
			// width: 290px;
			// width: 150px;
			flex: 1;
			max-width: 300px;


			.group-info {
				padding: 0 14px;
				height: 60px;
				line-height: 60px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.group-add {
				display: flex;
				justify-content: flex-start;
				padding: 24px 24px;

				.btn {
					cursor: pointer;
					text-align: center;
					width: 111px;
					height: 34px;
					background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
					border-radius: 17px 17px 17px 17px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
				}
			}

			.group-items {
				min-height: 400px;
				flex: 1;

				.bumen-item {
					height: 38px;
					display: flex;
					align-items: center;
					justify-content: space-between;
					padding-right: 0;
					cursor: pointer;

					&.selected {
						background: #E5F2FF;
					}

					&:hover {
						.bumen-act {
							display: block;
						}
					}


					.bumen-icon {
						margin-left: 24px;
						margin-left: 12px;
						width: 16px;
						height: 14px;

						img {
							width: 16px;
							height: 14px;
						}
					}

					.bumen-title {
						padding-left: 8px;
						flex: 1;
						overflow: hidden;
						text-align: left;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						font-size: 13px;
						color: #333333;
					}

					.bumen-act {
						display: none;

						.more-box {
							width: 40px;
							height: 38px;

							.more {
								width: 16px;
								height: 3px;
							}
						}

					}
				}
			}
		}
	}




	.tag-actions {
		li {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 30px;
			font-size: 14px;
			cursor: pointer;

			&:hover {
				// color: #33942c;
			}

			&.tag-delete:hover {
				color: #f56c6c;
			}

			.tag-del-text {
				color: #f56c6c;
			}
		}

		.tag-common-icon {
			justify-content: left;
		}

		.tag-common-icon>img {
			width: 16px;
			height: 16px;
			margin-right: 10px;
		}
	}

	/deep/ [class*="el-icon-"] {
		padding: 10px 16px;
		display: inline-block;
		cursor: pointer;

		&:hover {
			color: #33942c;
		}
	}




	.page {}

	.page-title {
		font-family: Microsoft YaHei, Microsoft YaHei;
		font-weight: bold;
		font-size: 14px;
		color: #333333;
	}

	.page-ctx {
		margin-top: 20px;
	}

	.group-box {
		background: #fff;
	}


	.position-box {
		background: #fff;
		margin-top: 20px;
		padding: 20px;

		.filter-box {
			.title {

				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.search-box {
				flex: 1;
				justify-content: flex-end;

				.search-text {
					margin-right: 20px;
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #333333;
				}

				.search-input {
					.btn-search {
						// background: #fff;
					}
				}
			}

			.actions {
				.btn {
					cursor: pointer;
					margin-left: 15px;
					text-align: center;
					width: 87px;
					height: 31px;
					line-height: 31px;
					background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
					border-radius: 4px 4px 4px 4px;

					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: 400;
					font-size: 14px;
					color: #FFFFFF;
				}
			}
		}
	}

	.table-box {
		.table-view {
			// width: 1640px;
			// height: 670px;
			width: 100%;
			padding: 20px 0;
		}
	}

	.pages {
		position: relative;
		z-index: 2;
		padding-top: 40px;
	}
</style>